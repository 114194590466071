import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { VentanasPagosContratosService } from 'src/app/ventanas/services/ventanas-pagos-contratos.service';
import { VentanaSolicitudContratoService } from 'src/app/ventanas/services/ventana-solicitud-contrato.service';

import * as CatalogoVentanasApiActions from './ventanas-api.actions';
import { catchError, combineLatest, concatMap, map } from 'rxjs';
import { VentanaSolcitudContratoConfig, VentanaSolicitudPagoContratoConfig } from '../models/ventana-config.model';
import { AlertService } from '@core/services';
import { DIAS_SEMANA } from '@shared/constantes.global';

@Injectable()
export class VentanasEffects {
  private readonly _ventanasPagosService = inject(VentanasPagosContratosService);
  private readonly _ventanasSolContratoService = inject(VentanaSolicitudContratoService);
  private readonly actions$ = inject(Actions);
  private readonly _alertService = inject(AlertService);

  GET_VENTANAS_DATA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CatalogoVentanasApiActions.initCatalogueVentanas),
      concatMap(() =>
        combineLatest([
          this._ventanasPagosService.getConfiguracion(),
          this._ventanasSolContratoService.getConfiguracion(),
        ]).pipe(
          map(([configPagos, configSolContrato]) => {
            let ventanaSolContratosConfigAll: VentanaSolcitudContratoConfig[];
            let ventanaSolPagosContratosConfigAll: VentanaSolicitudPagoContratoConfig[];

            if (configPagos.code === 200 && Array.isArray(configPagos.data) && configPagos.data.length > 0) {
              ventanaSolPagosContratosConfigAll = configPagos.data.map((item) => {
                return {
                  ...item,
                  dia_semana_name:
                    typeof item.dia_semana === 'string'
                      ? JSON.parse(item.dia_semana).map((dia) => DIAS_SEMANA[dia])
                      : item.dia_semana.map((dia) => DIAS_SEMANA[dia]),
                };
              });
            }

            if (
              configSolContrato.code === 200 &&
              Array.isArray(configSolContrato.data) &&
              configSolContrato.data.length > 0
            ) {
              ventanaSolContratosConfigAll = configSolContrato.data.map((item) => {
                return {
                  ...item,
                  dia_semana_name:
                    typeof item.dia_semana === 'string'
                      ? JSON.parse(item.dia_semana).map((dia) => DIAS_SEMANA[dia])
                      : item.dia_semana.map((dia) => DIAS_SEMANA[dia]),
                };
              });
            }

            return CatalogoVentanasApiActions.loadSuccessCatalogueVentanas({
              ventanaSolContratosConfigAll,
              ventanaSolPagosContratosConfigAll,
            });
          }),
          catchError(async () =>
            CatalogoVentanasApiActions.loadErrorCatalogueVentanas({
              errorMessage: 'Error al cargar catálogo de ventanas STATE EFFECTS, informe al administrador',
            })
          )
        )
      )
    );
  });

  NOTIFY_ERROR$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CatalogoVentanasApiActions.loadErrorCatalogueVentanas),
        map((action) => {
          this._alertService.error(action.errorMessage);
        })
      );
    },
    { dispatch: false }
  );
}
