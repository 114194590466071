import { createSelector } from '@ngrx/store';
import { StateVentanasApi } from './ventanas.reducer';
import { AppState } from 'src/app/app.state';

const selectVentanas = (state: AppState) => state.ventanasState;

export const selectVentanasContratosConfig = createSelector(
  selectVentanas,
  (state: StateVentanasApi) => state.ventanaSolContratosConfigAll
);

export const selectVentanasPagoContratosConfig = createSelector(
  selectVentanas,
  (state: StateVentanasApi) => state.ventanaSolPagosContratosConfigAll
);

export const selectVentanasLoaded = createSelector(selectVentanas, (state: StateVentanasApi) => state.loaded);
