import { createReducer, on } from '@ngrx/store';
import {
  VentanaSolcitudContratoConfig,
  VentanaSolicitudPagoContratoConfig,
} from 'src/app/ventanas/models/ventana-config.model';

import { CatalogoVentanasApiActions } from '.';

export interface StateVentanasApi {
  ventanaSolContratosConfigAll: VentanaSolcitudContratoConfig[];
  ventanaSolPagosContratosConfigAll: VentanaSolicitudPagoContratoConfig[];
  loaded: boolean;
}

const initialState: StateVentanasApi = {
  ventanaSolContratosConfigAll: [],
  ventanaSolPagosContratosConfigAll: [],
  loaded: false,
};

export const ventanasApiReducer = createReducer<StateVentanasApi>(
  initialState,
  on(
    CatalogoVentanasApiActions.loadSuccessCatalogueVentanas,
    (state, action): StateVentanasApi => ({
      ...state,
      ventanaSolContratosConfigAll: action.ventanaSolContratosConfigAll,
      ventanaSolPagosContratosConfigAll: action.ventanaSolPagosContratosConfigAll,
      loaded: true,
    })
  )
);
