import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  VentanaSolcitudContratoConfig,
  VentanaSolicitudPagoContratoConfig,
} from 'src/app/ventanas/models/ventana-config.model';

const CatalogoVentanasApiActions = createActionGroup({
  source: 'CatalogueVentanas API',
  events: {
    initCatalogueVentanas: emptyProps(),
    loadSuccessCatalogueVentanas: props<{
      ventanaSolContratosConfigAll: VentanaSolcitudContratoConfig[];
      ventanaSolPagosContratosConfigAll: VentanaSolicitudPagoContratoConfig[];
    }>(),
    loadErrorCatalogueVentanas: props<{ errorMessage: string }>(),
  },
});

export const { initCatalogueVentanas, loadSuccessCatalogueVentanas, loadErrorCatalogueVentanas } =
  CatalogoVentanasApiActions;
